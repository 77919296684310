import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84')
];

export const server_loads = [0,2,17,18,19,4,5,6,7,8,9,10,11,12,15,20,21,16,22,23,24,25];

export const dictionary = {
		"/(app)": [27,[2]],
		"/(app)/audit-log": [52,[2]],
		"/(app)/buildings": [53,[2,17]],
		"/(app)/buildings/[id]": [54,[2,17,18]],
		"/(app)/buildings/[id]/add-asset": [~55,[2,17,18]],
		"/(app)/buildings/[id]/assets/[nodeAddress]": [56,[2,17,18,19]],
		"/(app)/buildings/[id]/assets/[nodeAddress]/edit": [~57,[2,17,18,19]],
		"/(app)/buildings/[id]/assets/[nodeAddress]/move-building": [~58,[2,17,18,19]],
		"/(app)/buildings/[id]/edit": [~59,[2,17,18]],
		"/(app)/buildings/[id]/scheduling": [~60,[2,17,18]],
		"/(app)/(developer)/developer": [28,[2,3]],
		"/(app)/(developer)/developer/apps": [29,[2,3,4]],
		"/(app)/(developer)/developer/apps/create": [37,[2,3,4]],
		"/(app)/(developer)/developer/apps/[id]": [30,[2,3,4,5]],
		"/(app)/(developer)/developer/apps/[id]/api-docs": [~31,[2,3,4,5]],
		"/(app)/(developer)/developer/apps/[id]/edit": [32,[2,3,4,5,6]],
		"/(app)/(developer)/developer/apps/[id]/request-log": [33,[2,3,4,5]],
		"/(app)/(developer)/developer/apps/[id]/webhook-events": [34,[2,3,4,5,7]],
		"/(app)/(developer)/developer/apps/[id]/webhooks": [35,[2,3,4,5,8]],
		"/(app)/(developer)/developer/apps/[id]/webhooks/create": [~36,[2,3,4,5,8]],
		"/(app)/(developer)/developer/request-log": [38,[2,3]],
		"/(app)/(developer)/developer/webhook-events": [39,[2,3,9]],
		"/(app)/(developer)/developer/webhooks": [40,[2,3,10]],
		"/(app)/(developer)/developer/webhooks/[id]": [41,[2,3,10,11]],
		"/(app)/(developer)/developer/webhooks/[id]/edit": [42,[2,3,10,11,12]],
		"/(theme)/dev/theme": [84],
		"/(security)/login": [~81,[26]],
		"/(security)/login/[token]": [~82,[26]],
		"/(security)/logout": [~83,[26]],
		"/(app)/(products)/manufacturers": [45,[2]],
		"/(app)/(products)/manufacturers/add": [48,[2]],
		"/(app)/(products)/manufacturers/[id]": [46,[2,15]],
		"/(app)/(products)/manufacturers/[id]/edit": [47,[2,15]],
		"/(app)/(network)/network": [43,[2,13]],
		"/(app)/(network)/network/nodes": [44,[2,13]],
		"/(app)/n/[nodeAddress]": [~61,[2]],
		"/(app)/n/[nodeAddress]/add": [~62,[2]],
		"/(app)/organisations": [63,[2,20]],
		"/(app)/organisations/add": [~69,[2,20]],
		"/(app)/organisations/[id]": [64,[2,20,21]],
		"/(app)/organisations/[id]/add-building": [~65,[2,20,21]],
		"/(app)/organisations/[id]/add-user": [~66,[2,20,21]],
		"/(app)/organisations/[id]/edit": [~67,[2,20,21]],
		"/(app)/organisations/[id]/snapshot-report": [~68,[2,20,21]],
		"/(app)/(products)/products": [49,[2]],
		"/(app)/(products)/products/[id]": [50,[2,16]],
		"/(app)/(products)/products/[id]/edit": [51,[2,16]],
		"/(app)/profile/unsubscribe": [~70,[2]],
		"/(app)/reports": [71,[2,22]],
		"/(app)/reports/template-editor": [~73,[2,22]],
		"/(app)/reports/[id]": [~72,[2,22]],
		"/(app)/testing/log": [~75,[2,23]],
		"/(app)/testing/log/queued": [76,[2,23]],
		"/(app)/testing/[id]": [~74,[2,23]],
		"/(app)/users": [77,[2,24]],
		"/(app)/users/add": [~80,[2,24]],
		"/(app)/users/[id]": [78,[2,24,25]],
		"/(app)/users/[id]/edit": [~79,[2,24,25]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';